<template>
<div class="modal fade text-left" id="singleSmsModal" tabindex="-1" role="dialog" aria-labelledby="singleSmsModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h5 class="modal-title white" id="">Send SMS</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div class="">
                   
                    <div class="form-group">
                        <div>
                            <label>SMS Text</label>
                            <textarea v-model="smsText" rows="7" id="usageCondition" class="form-control"></textarea>
                        </div>
                        <div class="text-danger" v-if="errors.sms">{{ errors.sms }}</div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-light-secondary" data-dismiss="modal">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Close</span>
                </button>
                <button :disabled="isSendSMSButtonDisabled" type="button" @click="sendSmsHandler" class="btn btn-sm btn-danger ml-1">
                    <span class="">Send</span>
                </button>
            </div>
        </div>
    </div>
</div>

</template>

<script>
// components

// mixins
import Authorization from "@/components/backEnd/mixins/Authorization";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
// core packages
import {
    mapActions,
    mapGetters
} from "vuex";

export default {
    name: "SingleSmsSendModal",
    props: ['userPhoneNumber'],
    components: {

    },
    mixins: [ShowToastMessage, Loader, Authorization],
    data() {
        return {

            phoneNumber: null,
            smsText: null,

            errors: {
                phoneNumber: null,
                sms: null
            },
        }

    },
    computed: {
        ...mapGetters({
            previousRoute: 'previousRoute',

        }),

        isSendSMSButtonDisabled() {
            return this.smsText === null || this.smsText.length === 0 || this.phoneNumber === null || this.phoneNumber.length < 10 || this.phoneNumber.length > 10;
        }

    },
    watch: {
        userPhoneNumber(userPhoneNumber) {
            this.phoneNumber = userPhoneNumber;
        }
    },

    methods: {
        ...mapActions({

            // smsMarketingSendNotification: 'smsMarketings/smsMarketingSendNotification',
            sendSingleSms: 'smsMarketings/sendSingleSms',
        }),

        async resetErrors() {
            this.errors = {
                type: null,
                state: null,
                postCode: null,
                sms: null
            };
        },

        async resetData() {

            this.smsText = null;
            this.phoneNumber = null;

        },

        async sendSmsHandler() {

            let data = {
                recipient: `+61${this.phoneNumber}`,
                body: this.smsText
            };
            this.loader(true);
            const response = await this.sendSingleSms(data);
            this.loader(false);
            console.log('====================================');
            console.log(response);
            console.log('====================================');
            if (response.status === 201 || response.status === 200) {
                const toastObj = {
                    message: 'Sms send successful.',
                    type: 'success'
                };
                this.showToastMessage(toastObj);
                document.querySelector('[data-target="#singleSmsModal"]').click();

                this.resetErrors();
                this.resetData();
                return;
            }

            if (response.message) {
                this.showToastMessage(response);
            }

        }
    },
    

}
</script>


