<template>
  <div class="modal fade text-left" :id="modalName" tabindex="-1" role="dialog" :aria-labelledby="modalName" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header bg-warning">
          <h5 class="modal-title white" id="">Caution on Updating</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="text-center mx-2">
            <i style="font-size: 4rem;" class='bx bx-error bx-flashing text-warning mx-2' ></i>
            <br>
            <span class="bold">Do you really want to update this records?</span>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-light-secondary" data-dismiss="modal">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Close</span>
          </button>
          <button type="button" class="btn btn-sm btn-warning ml-1" data-dismiss="modal" @click="confirmModelUpdating">
            <i class="bx bx-check d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Update</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpdateAlertModal',
  props: {
    modalName: {
      type: String,
      default: "updateAlertModal",
      required: true,
    },
    modelName: {
      type: String,
      default: "",
      required: true,
    },
    modelForUpdating: {
      type: Object,
      required: true
    },
  },
  methods: {
    confirmModelUpdating() {
      this.$emit('confirmModelUpdating', {
        modelForUpdating: this.modelForUpdating,
        modelName: this.modelName,
      });
    },
  }
};
</script>

<style>

</style>
