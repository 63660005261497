<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Customers/List</h4>
          <div>
            <router-link :to="can('customer-create') ? {name:'appCustomerCreate'} : '#'">
              <span class="glow d-flex align-items-center"
                    :title="can('customer-create') ? 'Create' : 'Create Forbidden' ">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create
              </span>
            </router-link>
          </div>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center ">
              <div class="col-12 col-sm-12 col-md-2">
                <div class="form-group">
                  <label>Show Per Page</label>
                  <VueMultiselect v-model="selectedPagination"
                                  :options="paginationList"
                                  :close-on-select="true" label="name" track-by="name"
                                  :show-labels="false" :allow-empty="false"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-2">
                <div class="form-group">
                  <label>Customer Type</label>
                  <VueMultiselect v-model="selectedCustomerType"
                                  :options="customerTypeList" :close-on-select="true"
                                  label="name" track-by="name"
                                  :show-labels="false" :allow-empty="false"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-2">
                <div class="form-group">
                  <label>State</label>
                  <VueMultiselect v-model="selectedState"
                                  :options="stateList" :close-on-select="true"
                                  label="name" track-by="name"
                                  :show-labels="false" :allow-empty="false"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-2">
                <div class="form-group">
                  <label>Status</label>
                  <VueMultiselect v-model="selectedStatus"
                                  :options="statuses" :close-on-select="true"
                                  placeholder="Select status" label="name" track-by="name"
                                  :show-labels="false" :allow-empty="false"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-2">
                <div class="form-group">
                  <label>Create Date From</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="w-100 ">
                      <DatePicker v-model="getCustomersParams.created_at[0]" :update-on-input="true"
                                  :masks="{input: ['DD MMMM YYYY']}"
                                  :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                        <template v-slot="{ inputValue ,togglePopover  }">
                          <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                            <i class='bx bx-calendar mr-1'></i>
                            <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                          </div>
                        </template>
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-2">
                <div class="form-group">
                  <label>Create Date To</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="w-100 ">
                      <DatePicker v-model="getCustomersParams.created_at[1]" :update-on-input="true"
                                  :masks="{input: ['DD MMMM YYYY']}"
                                  :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                        <template v-slot="{ inputValue ,togglePopover  }">
                          <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                            <i class='bx bx-calendar mr-1'></i>
                            <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                          </div>
                        </template>
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12 col-sm-12 col-md-9">
                <div class="form-group">
                  <label>Customer Or Address Search</label>
                  <input v-model="getCustomersParams.where_has_user_search_query"
                         class="form-control search-product-input-element" type="text"
                         placeholder="Search by Customer or Address">
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <div class="form-group">
                  <label style="visibility: hidden">Filter & Preview</label>
                  <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                          @click="applyCustomerFilter(null)">Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body" >
              <div class="table-responsive" style="min-height: 100vh;">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'5%'}">
                      SL
                    </th>
                    <th class="position-relative" :style="{width:'20%'}">
                      NAME
                    </th>
                    <th class="position-relative" :style="{width:'20%'}">
                      ADDRESS
                    </th>
                    <th class="position-relative" :style="{width:'10%'}">
                      TYPE
                    </th>
                    <th class="position-relative" :style="{width:'20%'}">
                      EMAIL
                    </th>
                    <th class="position-relative" :style="{width:'10%'}">
                      PHONE
                    </th>
                    <th class="position-relative" :style="{width:'10%'}">
                      STATUS
                    </th>
                    <th :style="{width:'5%'}">
                      ACTIONS
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(customer, index) in customers" :key="index">
                    <td>{{ index+1 }}</td>
                    <td>
                      <div>
                        <span class="customer-name" v-if="customer.user">{{ customer.user.full_name }}</span>
                      </div>
                      <div v-if="customer.address && customer.address.business">
                        <small class="text-warning">{{ customer.address.business.name }}</small>
                      </div>
                      <small>Creation Date: {{ customer.created_at }}</small>
                    </td>
                    <td v-if="customer.address">
                      {{ customer.address.street }}, {{ customer.address.suburb }}
                      {{ customer.address.state.toUpperCase() }}
                      {{ customer.address.post_code }}, {{ customer.address.country }}
                    </td>
                    <td>
                      <span v-if="customer.type === 'Home'" class="bullet bullet-primary bullet-sm"> </span>
                      <span v-else-if="customer.type === 'Business'" class="bullet bullet-success bullet-sm"> </span>
                      <span v-else class="bullet bullet-warning  bullet-sm"></span>
                      <span style="margin-left: 1rem">{{ customer.type }}</span>
                    </td>
                    <td>{{ customer.user.email }}</td>
                    <td>{{ customer.user.phone_number }}</td>
                    <td><span class="badge"
                              :class="customer.status === 'Active' ? 'badge-light-success' : 'badge-light-danger'">
                      {{ customer.status }}
                    </span></td>
                    <td>
                      <div class="d-flex align-items-top justify-content-between">
                        <router-link :to="can('customer-view') ? {name:'appCustomerView',params:{id:customer.id}} : '#'"
                                     class=" cursor-pointer view-edit-delete-icon" :style="{height:'18px'}"
                                     :title="can('customer-view') ? 'View' : 'View forbidden'">
                          <i class='bx bx-show'></i>
                        </router-link>
                        <button
                            class="m-0 p-0 bg-transparent text-decoration-none cursor-pointer border-0 view-edit-delete-icon"
                            :disabled="!can('customer-update')"
                            :title="can('customer-update') ? `${customer.newsletter_subscription} | Click to update` : `${customer.newsletter_subscription} | Update forbidden`"
                            :style="{height:'18px'}"
                            data-toggle="modal"
                            data-target="#customerNewsletterSubscriptionUpdateAlertModal"
                            @click="this.modelForUpdating = {modelId: customer.id, existingData: {newsletterSubscription: customer.newsletter_subscription}}">
                          <i :class="customer.newsletter_subscription === 'Subscribed' ? 'bx bx-bell' : 'bx bx-bell-off'"></i>
                        </button>
                        <div class="dropdown py-0 px-0 view-edit-delete-icon" :style="{height:'18px'}">
                          <span type="button" class="px-0 py-0" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                            <i class='bx bx-dots-vertical-rounded h-100'></i>
                          </span>
                          <div class="dropdown-menu">
                            <button class="dropdown-item"
                                    :disabled="!can('customer-delete')"
                                    :title="can('customer-delete') ? 'Delete' : 'Delete forbidden'"
                                    data-toggle="modal" data-target="#customerDeleteAlertModal"
                                    @click="this.modelIdForDelete = customer.id">Delete
                            </button>
                            <button :disabled="!can('coupon-create')"
                                    :title="can('coupon-create') ? 'Create coupon' : 'Create coupon forbidden'"
                                    class="dropdown-item"
                                    @click="selectedCustomer = customer"
                                    data-toggle="modal" data-target="#customerCouponCreateModal">Tag a Coupon
                            </button>
                            <button :disabled="!can('customer-update')"
                                    class="dropdown-item"
                                    @click="openRestPasswordModal(customer)"
                                   >Reset Password
                            </button>
                            <button 
                                    class="dropdown-item"
                                    @click="goToAppointments(customer.id)"
                                   >view appointments
                            </button>
                            <button  v-if="customer?.user?.phone_number !== null"
                                    class="dropdown-item"
                                    @click="openSendSmsModal(customer.user.phone_number)"
                                   >send sms
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <ListPagination position="right" :pagination-data="paginateLinks"
                                @getClickedPage="applyCustomerFilter"/>
              </div>
            </div>
          </div>
        </div>
        <div class="" data-toggle="modal" data-target="#restPasswordModal"></div>
        <div class="" data-toggle="modal" data-target="#singleSmsModal"></div>
        <customer-coupon-create-modal :current-customer="selectedCustomer"></customer-coupon-create-modal>
        <ResetPasswordModal :userEmailOrPhone="resetPasswordUserEmailOrPhone"/>
        <customer-delete-alert-modal :model-id="modelIdForDelete" model-name="customer"
                                     modal-name="customerDeleteAlertModal"
                                     @confirmModelDeletion="confirmModelDeletion"/>
        <notification-subscription-update-alert-modal :model-for-updating="modelForUpdating" model-name="customer"
                                                      modal-name="customerNewsletterSubscriptionUpdateAlertModal"
                                                      @confirmModelUpdating="customerNewsletterSubscriptionUpdating"/>
        <SingleSmsSendModal :userPhoneNumber="selectedUserPhoneNumber"/>                                              

      </section>
    </template>
  </AppLayout>
</template>

<script>
//component
import AppLayout from '@/layouts/backEnd/AppLayout'
import CustomerDeleteAlertModal from '@/components/backEnd/modal/DeleteAlertModal';
import NotificationSubscriptionUpdateAlertModal from '@/components/backEnd/modal/UpdateAlertModal';
import ResetPasswordModal from '@/components/backEnd/modal/ResetPasswordModal';
import CustomerCouponCreateModal from "@/views/backEnd/users/customers/includes/CustomerCouponCreateModal";
import ListPagination from '@/components/backEnd/pagination/ListPagination';


//mixin
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

//core package
import {
  mapActions,
  mapGetters
} from "vuex";

// package
import VueMultiselect from 'vue-multiselect';
import {DatePicker} from 'v-calendar';
import SingleSmsSendModal from '@/components/backEnd/modal/SingleSmsSendModal.vue';

export default {
  name: "CustomerList",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
    CustomerDeleteAlertModal,
    ListPagination,
    VueMultiselect,
    NotificationSubscriptionUpdateAlertModal,
    CustomerCouponCreateModal,
    DatePicker,
    ResetPasswordModal,
    SingleSmsSendModal
},
  data() {
    return {
      resetPasswordUserEmailOrPhone:"",
      selectedUserPhoneNumber:null,
      selectedCustomer: {},
      customer: {},
      statuses: [{
        value: '',
        name: 'Any'
      },
        {
          value: 0,
          name: 'Inactive'
        },
        {
          value: 1,
          name: 'Active'
        },
      ],
      selectedPagination: {
        value: '',
        name: 'Default'
      },
      selectedCustomerType: {
        value: '',
        name: 'Any'
      },
      selectedState: {
        value: '',
        name: 'Any'
      },
      selectedStatus: {
        value: '',
        name: 'Any'
      },
      selectedCreatedDateFrom: '',
      selectedCreatedDateTo: '',
      modelIdForDelete: '',
      modelForUpdating: {
        ModelId: '',
        existingData: {},
      },

      getCustomersParams: {
        where_has_user_search_query: '',
        where_has_address_state: '',
        type: '',
        status: '',
        created_at: [],
        with_relation: ['user', 'address', 'address.business', 'referredBy'],
        order_by_id: 'DESC',
        paginate: 1,
        pagination: '',
        page: ''
      },
      getSettingsParams: {
        type: ['default'],
        key: ['default_pagination', 'default_state', 'default_customer_type'],
      },
    }

  },
  watch: {
    selectedPagination: function (pagination) {
      this.getCustomersParams.pagination = pagination.value;
    },
    selectedState: function (state) {
      this.getCustomersParams.where_has_address_state = state.value;
    },
    selectedCustomerType: function (customerType) {
      this.getCustomersParams.type = customerType.value;
    },
    selectedStatus: function (status) {
      this.getCustomersParams.status = status.value;
    },
    selectedCreatedDateFrom(selectedCreatedDateFrom) {
      this.getCustomersParams.created_at[0] = selectedCreatedDateFrom;
    },
    selectedCreatedDateTo(selectedCreatedDateTo) {
      this.getCustomersParams.created_at[1] = selectedCreatedDateTo;
    },
  },
  computed: {
    ...mapGetters({
      customers: 'appCustomers/customers',
      paginateLinks: 'appCustomers/paginateLinks',
    }),
    paginationList() {
      return [{
        value: this.$store.getters['appSettings/settingDefaultPagination'].value,
        name: 'Default'
      }, {
        value: 25,
        name: '25 Entries'
      }, {
        value: 50,
        name: '50 Entries'
      }, {
        value: 100,
        name: '100 Entries'
      }];
    },
    stateList() {
      return [{
        value: '',
        name: 'Any'
      }, ...this.$store.getters['appSettings/settingDefaultState'].value];
    },
    customerTypeList() {
      return [
        {
          value: '',
          name: 'Any'
        },
        ...this.$store.getters['appSettings/settingDefaultCustomerType'].value,
      ];
    },
  },
  methods: {
    ...mapActions({
      getCustomers: 'appCustomers/getCustomers',
      getSettings: 'appSettings/getSettings',
      putCustomerOnList: 'appCustomers/putCustomerOnList',

      deleteCustomerOnList: 'appCustomers/deleteCustomerOnList',
    }),
    openSendSmsModal(phoneNumber){
      this.selectedUserPhoneNumber = null;
      this.selectedUserPhoneNumber = phoneNumber;
      this.$nextTick(function () {
        document.querySelector('[data-target="#singleSmsModal"]').click();
      })
     
     
    },
    openRestPasswordModal(customer){
      this.resetPasswordUserEmailOrPhone = "";
      if (customer?.user?.email) {
        this.resetPasswordUserEmailOrPhone = customer.user.email;
        document.querySelector('[data-target="#restPasswordModal"]').click();
        return;
      }
      if (customer?.user?.phone_number) {
        this.resetPasswordUserEmailOrPhone = customer.user.phone_number;
        document.querySelector('[data-target="#restPasswordModal"]').click();
        return;
      }
      console.log(customer, "customer ");
    },
    async getCustomerList() {
      await this.getCustomers(this.getCustomersParams)
          .then((response) => {
            if (response && response.message && response.status !== 200) {
              this.showToastMessage(response);
            }
          });
    },
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },
    async applyCustomerFilter(pageNumber) {
      await this.loader(true);
      this.getCustomersParams.page = pageNumber;
      await this.getCustomerList();
      await this.loader(false);
    },

    async deleteSingleCustomerOnLIst(id) {
      this.deleteCustomerOnList(id).then(async (response) => {
        if (response.status === 200 || response.status === 204) {
          const toastObj = {
            message: "Customer deleted successfully",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },

    async confirmModelDeletion(confirmModelDeletion) {
      await this.deleteSingleCustomerOnLIst(confirmModelDeletion.modelId);
    },

    async customerNewsletterSubscriptionUpdatingOnList(customerNewsletterSubscriptionUpdating) {
      let dataObj = {
        id: customerNewsletterSubscriptionUpdating.modelForUpdating.modelId,
        data: {
          newsletter_subscription: customerNewsletterSubscriptionUpdating.modelForUpdating.existingData.newsletterSubscription === "Subscribed" ? 0 : 1,
        }
      }

      this.putCustomerOnList(dataObj).then(async (response) => {
        if (response.status === 200) {
          const toastObj = {
            message: "Customer's newsletter subscription status updated",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },

    goToAppointments(customerId){
    
             this.$router.push({
              name: 'appAppointmentList',
              params: {
                customerId: customerId,
              },
            });
          
    },

    async customerNewsletterSubscriptionUpdating(customerNewsletterSubscriptionUpdating) {
      await this.customerNewsletterSubscriptionUpdatingOnList(customerNewsletterSubscriptionUpdating);
    },
  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.getCustomerList();
    await this.loader(false);
  }

}
</script>

<style scoped>
.customer-name {
  color: #5A8DEE;
}

.search-product-input-element {
  min-height: 41px !important;
  padding: 8px 40px 8px 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}
</style>
