<template>
  <div class="modal fade " id="customerCouponCreateModal" tabindex="-1" role="dialog"
       aria-labelledby="customerCouponCreateModal"
       aria-hidden="true" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog  modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Add Coupon</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">

          <div>
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <div class="">
                        <label>Coupon Name </label>
                        <input v-model="postCouponData.name" type="text" class="form-control"
                               placeholder="Example: Black Friday" name="">
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <div class="controls">
                        <label>Coupon Code </label>
                        <input v-model="postCouponData.code" type="text" class="form-control"
                               placeholder="Example: KB2020"
                               name="">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <!--                  <div class="col-12 col-md-3">
                                      <div class="form-group">
                                        <div class="controls">
                                          <label>Type</label>
                                          <div class="" style="margin-top: .5rem">
                                            <div
                                                class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                              <div>
                                                <input v-model="typeStatus" type="checkbox" class="custom-control-input" checked
                                                       id="typeStatusCheckbox" disabled>
                                                <label class="custom-control-label mr-1" for="typeStatusCheckbox"></label>
                                              </div>
                                              <span class="font-medium-1">{{ !typeStatus ? "Assigned Only" : "Global" }}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>-->
                  <div class="col-12 col-md-4">
                    <div class="form-group">
                      <div class="controls">
                        <label>Discount Type</label>
                        <div class="" style="margin-top: .5rem">
                          <div
                              class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                            <div>
                              <input v-model="discountTypeStatus" type="checkbox" class="custom-control-input"
                                     checked id="discountTypeStatusCheckbox">
                              <label class="custom-control-label mr-1" for="discountTypeStatusCheckbox"></label>
                            </div>
                            <span class="font-medium-1">{{
                                !discountTypeStatus ? "Percentage" : "Fixed Amount"
                              }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="form-group">
                      <div class="controls">
                        <label>Discount</label>
                        <input v-model="postCouponData.discount" type="text" class="form-control" placeholder=""
                               name="">
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="form-group">
                      <div class="controls">
                        <label>Activation Amount</label>
                        <input v-model="postCouponData.activation_amount" type="text" class="form-control"
                               placeholder=""
                               name="">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-md-2">
                    <div class="form-group">
                      <div class="controls">
                        <label>Apply on</label>
                        <div class="" style="margin-top: .5rem">
                          <div
                              class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                            <div>
                              <input v-model="applyOnStatus" type="checkbox" class="custom-control-input"
                                     checked id="applyOnStatusCheckbox">
                              <label class="custom-control-label mr-1" for="applyOnStatusCheckbox"></label>
                            </div>
                            <span class="font-medium-1">{{
                                !applyOnStatus ? "Subtotal Cost" : "Shipping Cost"
                              }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-5">
                    <div class="form-group">
                      <div class="controls">
                        <label>Start Date </label>
                        <div class="d-flex form-control align-items-center date-picker-wrapper">
                          <div class="d-flex align-items-center">
                            <i class='bx bx-calendar'></i>
                          </div>
                          <div class="w-100 pl-1">
                            <div class="w-100 ">
                              <DatePicker v-model="postCouponData.start_at" :update-on-input="true"
                                          mode="dateTime"
                                          :min-date="new Date()"
                                          is24hr: true
                                          :model-config="{ type: 'string', mask: 'YYYY-MM-DD HH:mm:ss'}">
                                <template v-slot="{ inputValue, inputEvents }">
                                  <input class="form-control" :value="inputValue" v-on="inputEvents"/>
                                </template>
                              </DatePicker>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="col-12 col-md-5">
                    <div class="form-group">
                      <div class="controls">
                        <label>End Date </label>
                        <div class="d-flex form-control align-items-center date-picker-wrapper">
                          <div class="d-flex align-items-center">
                            <i class='bx bx-calendar'></i>
                          </div>
                          <div class="w-100 pl-1">
                            <div class="w-100 ">
                              <DatePicker v-model="postCouponData.end_at" :update-on-input="true"
                                          mode="dateTime"
                                          :min-date="new Date()"
                                          is24hr: true
                                          :model-config="{ type: 'string', mask: 'YYYY-MM-DD HH:mm:ss'}">
                                <template v-slot="{ inputValue, inputEvents }">
                                  <input class="form-control" :value="inputValue" v-on="inputEvents"/>
                                </template>
                              </DatePicker>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-4">
                    <div class="form-group">
                      <div class="controls">
                        <label>Uses Per Coupon </label>
                        <input v-model="postCouponData.usage_per_coupon" type="text" class="form-control" placeholder=""
                               name="">
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="form-group">
                      <div class="controls">
                        <label>Number of Coupon </label>
                        <input v-model="postCouponData.usage_per_user" type="number" class="form-control" placeholder=""
                               name="">
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="form-group">
                      <div class="controls">
                        <label>Status</label>
                        <VueMultiselect :allowEmpty="false" v-model="selectedStatus" class="multiselect-blue"
                                        :options="statusOptions"
                                        :close-on-select="true" placeholder="Select a status" label="name"
                                        track-by="name"
                                        :show-labels="false"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 ">
                    <div class="d-flex justify-content-end">
                      <button @click="singlePostCoupon" type="button" class="btn btn-primary update-todo px-4">Create
                        Coupon
                      </button>
                    </div>
                  </div>
                  <div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <!--        <div class="modal-footer border-0 pt-0">
                  <button type="button" class="btn btn-primary" @click="addCustomerCoupon">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Add</span>
                  </button>
                  &lt;!&ndash; data-dismiss="modal" &ndash;&gt;
                </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import {DatePicker} from 'v-calendar';
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    DatePicker
  },
  mixins: [ShowToastMessage, Loader],
  name: "CustomerCouponCreateModal",
  props: {
    currentCustomer: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      postCouponData: {
        creator_id: '',
        name: '',
        code: '',
        type: 0,
        discount_type: 0,
        discount: '',
        activation_amount: '',
        apply_on: 0,
        start_at: '',
        end_at: '',
        usage_per_coupon: '',
        usage_per_user: '',
        status: '',
      },
      selectedStatus: {
        value: '',
        name: 'None'
      },
      statusOptions: [
        {
          value: 0,
          name: 'Inactive',
        },
        {
          value: 1,
          name: 'Active',

        },
      ],
      typeStatus: false,
      discountTypeStatus: false,
      applyOnStatus: false,
    }
  },
  watch: {
    selectedStatus() {
      this.postCouponData.status = this.selectedStatus.value;
    },
    typeStatus(typeStatus) {
      this.postCouponData.type = typeStatus ? 1 : 0;
    },
    discountTypeStatus() {
      this.postCouponData.discount_type = this.discountTypeStatus ? 1 : 0
    },
    applyOnStatus() {
      this.postCouponData.apply_on = this.applyOnStatus ? 1 : 0
    }
  },
  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      authUser: 'appAuthLogin/authUser'
    }),
  },
  methods: {
    ...mapActions({
      postCoupon: 'appCoupons/postCoupon',
      postCouponUser: 'appCouponUser/postCouponUser'
    }),
    async singlePostCoupon() {
      this.loader(true);
      this.postCouponData.creator_id = this.authUser.id;
      await this.postCoupon({
        ...this.postCouponData,
        discount: this.postCouponData.discount_type === 1 ? this.postCouponData.discount * 100 : this.postCouponData.discount,
        activation_amount: this.postCouponData.activation_amount * 100
      }).then(async (response) => {
        if (response.status === 201) {
          let couponId = this.$store.getters['appCoupons/coupon'].id;
          this.loader(false);
          const toastObj = {message: 'Coupon created successful.', type: 'success'};
          // call coupon user api
          await this.singlePostCouponUser(couponId, this.currentCustomer.user.id);
          document.querySelector('[data-target="#customerCouponCreateModal"]').click();
          this.showToastMessage(toastObj);
        }
      });
    },

    async singlePostCouponUser(couponId, userId) {
      this.loader(true);
      const postCouponUserData = {
        coupon_id: couponId,
        user_id: userId
      }
      await this.postCouponUser(postCouponUserData).then(async (response) => {
        if (response.status === 201) {
          this.loader(false);
          const toastObj = {message: 'Coupon User created successful.', type: 'success'};
          this.showToastMessage(toastObj);
        }
      });
    },
  }
}
</script>
<style scoped>

</style>