<template>
  <div>Showing {{ showingRecordsFrom}} to {{ showingRecordsTo }} of {{ totalRecords }} records</div>
  <div class="col-md-12 d-flex pb-3" :class="viewPosition" v-if="status">
    <ul class="pagination justify-content-center mt-2">
        <li v-for="(link,key) in links" :key="key" @click="onClickPage(link.url)" class="page-item" :class="link.active ? 'active' :''">
          <button class="page-link" :disabled="link.url == null">
            <span  v-if="key === 0"><i class="bx bx-chevron-left"></i></span>
            <span  v-else-if="key+1 === links.length"><i class="bx bx-chevron-right"></i></span>
            <span  v-else >{{ link.label }}</span>
          </button>
        </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ListPagination',
  props:['paginationData', 'position'],
  emits:['getClickedPage'],
  data(){
    return{
      links: [],
      status: false,
    }
  },
  computed: {
    viewPosition() {
      switch(this.position) {
        case 'left':
          return "justify-content-start";
        case 'center':
          return "justify-content-center";
        case 'right':
          return "justify-content-end";
        default:
          return "justify-content-end";
      }
    },
    totalRecords() {
      return this.paginationData && this.paginationData.total ? this.paginationData.total : 0;
    },
    showingRecordsFrom() {
      return this.paginationData && this.paginationData.from ? this.paginationData.from : 0 ;
    },
    showingRecordsTo() {
      return this.paginationData && this.paginationData.to ? this.paginationData.to : 0;
    },
  },
  watch: {
    paginationData: function (paginationData) {
      this.status = paginationData && !(paginationData.current_page === 1 && paginationData.last_page === 1);
      this.links = paginationData.links;
    },
  },
  methods: {
    onClickPage(url) {
      if (url) {
        const pageNumber= url.toString().split('?page=')[1];
        this.$emit('getClickedPage', pageNumber);
      }
    }
  },
}
</script>