<template>
<div class="modal fade " id="restPasswordModal" tabindex="-1" role="dialog" aria-labelledby="restPasswordModal" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog  modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Reset Password </h1>
                <button type="button" class="close" @click="closeResetPasswordModal">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="row ">
                        <div class="col-12 form-group">
                            <label>Password</label>
                            <input v-model="password" type="password" class="form-control">

                        </div>
                        <div class="col-12 form-group">
                            <label>Confirm Password</label>
                            <input v-model="password_confirmation" type="password" class="form-control">

                        </div>

                    </div>

                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button type="button" class="btn btn-primary" @click="resetPassword">

                    <span class="">Reset </span>
                </button>

            </div>
            <div>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import {
    mapActions
} from 'vuex';
export default {
    name: "ResetPasswordModal",
    props: ['userEmailOrPhone'],
    mixins: [ShowToastMessage, Loader ],

    data() {
        return {
            username: '',
            password: '',
            password_confirmation: '',
        }
    },
    watch: {
        userEmailOrPhone(emailOrPhone) {
            if (emailOrPhone) {
                this.username = emailOrPhone;
            }
        }
      
    },
    methods: {
        ...mapActions({
            putResetPassword: 'appResetPassword/putResetPassword',

        }),

        async resetPassword() {
            this.loader(true);
            const response = await this.putResetPassword({username:this.username,password:this.password,password_confirmation:this.password_confirmation});
            this.loader(false);
            if (response.status === 200) {
                this.password = "";
                this.password_confirmation = "";
                this.showToastMessage({
                    type: 'success',
                    message: 'Password reset successfully'
                });
                document.querySelector('[data-target="#restPasswordModal"]').click();
                return;
            }
            if (response.message) {
                this.showToastMessage(response);
            }

        },
        closeResetPasswordModal() {
            this.password = "";
            this.password_confirmation = "";
            document.querySelector('[data-target="#restPasswordModal"]').click();
        }

    },

}
</script>

<style>

    </style>
